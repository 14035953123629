import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import PostList from "../components/PostList";
import { FormattedMessage } from "react-intl";
import Layout from "../components/Layout";
import { FaRegListAlt } from "react-icons/fa";

const chooser = (data, lang) => {
  let posts;
  let total;
  switch (lang) {
    case "en":
      posts = data.augmentedReality.edges.map((p) => p.node);
      total = data.augmentedReality.totalCount;
      break;
    case "it":
      posts = data.realtAumentata.edges.map((p) => p.node);
      total = data.realtAumentata.totalCount;
      break;
    default:
      return {};
  }
  return [posts, total];
};

const AugmentedRealityTemplate = ({ data, lang }) => {
  const posts = chooser(data, lang);

  return (
    <section className="section">
      <div className="container content">
        <header className="title is-size-3 has-text-weight-bold is-bold-light">
          <div className="content">
            <span className="tag is-light is-large">
              <FaRegListAlt className="menu-names" />
              <FormattedMessage
                id="articles.nPosts"
                values={{ nPosts: posts[1] }}
              />
            </span>
          </div>
        </header>
        <PostList posts={posts[0]} />
        <footer className="footer">
          <span className="box has-background-success is-light is-medium">
            <FormattedMessage id="articles.end" />
          </span>
        </footer>
      </div>
    </section>
  );
};

AugmentedRealityTemplate.propTypes = {
  posts: PropTypes.object,
  pageContext: PropTypes.object,
};

class AugmentedReality extends React.Component {
  render() {
    let data;
    if (this.props.data !== null) {
      data = this.props.data;
    }
    const jsonData = data.allArticlesJson.edges[0].node.articles;
    const lang = data.markdownRemark.frontmatter.lang;
    return (
      <Layout data={data} jsonData={jsonData} location={this.props.location}>
        <AugmentedRealityTemplate data={data} lang={lang} />
      </Layout>
    );
  }
}

export default AugmentedReality;

export const pageQuery = graphql`
  query AugmentedQuery($id: String!) {
    site {
      siteMetadata {
        languages {
          langs
          defaultLangKey
        }
      }
    }
    allArticlesJson(filter: { title: { eq: "home" } }) {
      edges {
        node {
          articles {
            en
            it
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        id
        title
        lang
        slug
        description
        section
      }
    }
    augmentedReality: allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          templateKey: { eq: "artworks-simple" }
          tags: { eq: "augmented-reality" }
        }
      }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            id
            title
            description
            section
            lang
            date
            slug
          }
          fields {
            langKey
            slug
            tagSlugs {
              tag
              link
            }
          }
          excerpt
        }
      }
    }
    realtAumentata: allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          templateKey: { eq: "artworks-simple" }
          tags: { eq: "realtà-aumentata" }
        }
      }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            id
            title
            description
            section
            lang
            date
            slug
          }
          fields {
            langKey
            slug
            tagSlugs {
              tag
              link
            }
          }
          excerpt
        }
      }
    }
  }
`;
